import { motion } from 'framer-motion'
import { FC } from 'react'
import { HiOutlineCheck } from 'react-icons/hi'

interface ProgressCirclesProps {
  currentIndex: number
  motionDirection: number
  steps: step[]
  moveTo: (targetIndex: number) => void
}

interface step {
  name: string
  status: string
}

const ProgressCircles: FC<ProgressCirclesProps> = ({ currentIndex, motionDirection, steps, moveTo }) => {
  function getProps() {
    if (motionDirection > 0) {
      return {
        animate: 'enter',
        initial: 'initial',
        variants: {
          initial: {
            opacity: 0,
          },
          enter: {
            opacity: 1,
          },
        },
      }
    } else
      return {
        animate: 'enter',
        initial: 'initial',
        variants: {
          initial: {
            opacity: 1,
          },
          enter: {
            opacity: 1,
          },
        },
      }
  }

  return (
    <nav aria-label='Progress'>
      <ol className='flex items-center'>
        {steps.map((step, index) => (
          <li key={step.name} className={`relative ${index !== steps.length - 1 && 'pr-8 sm:pr-20'}`}>
            {step.status === 'complete' ? (
              <>
                {motionDirection > 0 && (
                  <div className='absolute cursor-pointer inset-0 flex items-center' aria-hidden='true'>
                    {currentIndex - 1 === index ? (
                      <motion.div
                        style={{ originX: 0 }}
                        animate={{ scaleX: [0, 1] }}
                        transition={{ duration: 0.3 }}
                        className='h-0.5 w-full absolute bg-indigo-600'
                      />
                    ) : (
                      <div className='h-0.5 absolute w-full bg-indigo-600' />
                    )}
                    <div className='h-0.5 w-full bg-gray-200' />
                  </div>
                )}
                {motionDirection < 0 && (
                  <div className='absolute inset-0 flex items-center' aria-hidden='true'>
                    <div className='h-0.5 absolute w-full bg-indigo-600' />
                  </div>
                )}
                <div onClick={() => moveTo(index)} className='relative cursor-pointer w-8 h-8 flex items-center justify-center bg-indigo-600 rounded-full hover:bg-indigo-900'>
                  <HiOutlineCheck className='w-5 h-5 text-white' aria-hidden='true' />
                  <span className='sr-only'>{step.name}</span>
                </div>
              </>
            ) : step.status === 'current' ? (
              <>
                <div className='absolute inset-0 flex items-center' aria-hidden='true'>
                  {motionDirection < 0 && (
                    <motion.div
                      style={{ originX: 0 }}
                      animate={{ scaleX: [1, 0] }}
                      transition={{ duration: 0.3 }}
                      className='h-0.5 absolute w-full bg-indigo-600'
                    />
                  )}
                  <div className='h-0.5 w-full bg-gray-200' />
                </div>

                <motion.div
                  {...getProps()}
                  className='relative z-10 translate-y-1/2 w-8 h-8 flex items-center justify-center bg-white border-2 border-indigo-600 rounded-full'
                  aria-current='step'>
                  <span className='h-2.5 w-2.5 bg-indigo-600 rounded-full' aria-hidden='true' />
                  <span className='sr-only'>{step.name}</span>
                </motion.div>
                <div
                  className='relative -translate-y-1/2 w-8 h-8 flex items-center justify-center bg-white border-2 border-gray-200 rounded-full'
                  aria-current='step'>
                  <span className='h-2.5 w-2.5 bg-gray-200 rounded-full' aria-hidden='true' />
                  <span className='sr-only'>{step.name}</span>
                </div>
              </>
            ) : (
              <>
                <div className='absolute inset-0 flex items-center' aria-hidden='true'>
                  <div className='h-0.5 w-full bg-gray-200' />
                </div>
                <div className='group relative w-8 h-8 flex items-center justify-center bg-white border-2 border-gray-300 rounded-full hover:border-gray-400'>
                  <span
                    className='h-2.5 w-2.5 bg-transparent rounded-full group-hover:bg-gray-300'
                    aria-hidden='true'
                  />
                  <span className='sr-only'>{step.name}</span>
                </div>
              </>
            )}
          </li>
        ))}
      </ol>
    </nav>
  )
}

export default ProgressCircles
